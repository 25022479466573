import { combineReducers } from "redux";
import digitalLibraryReducer from "./DigitalLibrary";
import pypReducer from "./PYP";
import userReducer from "./User";
import applicationReducer from "./Application";
import spouseApplicationReducer from "./SpouseApplication";
import formularyReducer from "./Formulary";
import workshopReducer from "./Workshop";
import nonDodIcApplicationReducer from "./NonDodIcApplication"; // Import your new reducer

export default combineReducers({
  digitalLibrary: digitalLibraryReducer,
  pyp: pypReducer,
  ...userReducer,
  application: applicationReducer,
  spouseApplication: spouseApplicationReducer,
  nonDodIcApplication: nonDodIcApplicationReducer, // Add it here
  formulary: formularyReducer,
  workshop: workshopReducer,
});
