import ApplicationTypes from "./Application/types"
import SpouseApplicationTypes from "./SpouseApplication/types"
import NonDodIcApplicationTypes from "./NonDodIcApplication/types"
import DigitalLibraryTypes from "./DigitalLibrary/types"
import PYPTypes from "./PYP/types"
import UserTypes from "./User/types"
import FormularyTypes from "./Formulary/types"
import WorkshopTypes from "./Workshop/types"

const types = {
  ...ApplicationTypes,
  ...DigitalLibraryTypes,
  ...PYPTypes,
  ...UserTypes,
  ...FormularyTypes,
  ...WorkshopTypes,
  ...SpouseApplicationTypes,
  ...NonDodIcApplicationTypes
}

export default types
