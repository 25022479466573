import React, { useEffect } from "react"
import "./ApplicationEditor.css"
import { withRouter } from "react-router-dom"
import queryString from "query-string"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import Actions from "../../Redux/Actions"
import store from "../../Redux/store"


/*
    the primary objective of this component is to render inputs
    required Inputs = this.inputs = a hash map of components?
*/

const UserFieldEditor = (props) => {
  const dispatch = useDispatch()
  const { history, inputs, setInputData } = props;
  const spouseStoreData = store.getState().spouseApplication.application;
  const applicationStoreData = store.getState().application.application;
  const NonDodIcApplicationStoreData = store.getState().nonDodIcApplication.application;
  let storeData;
  if (spouseStoreData && Object.keys(spouseStoreData).length === 0 && NonDodIcApplicationStoreData && Object.keys(NonDodIcApplicationStoreData).length === 0) {
    storeData = applicationStoreData;
  } else if (applicationStoreData && Object.keys(applicationStoreData).length === 0 && NonDodIcApplicationStoreData && Object.keys(NonDodIcApplicationStoreData).length === 0) {
    storeData = spouseStoreData;
  } else if ( spouseStoreData && Object.keys(spouseStoreData).length === 0 && applicationStoreData && Object.keys(applicationStoreData).length === 0) {
    storeData = NonDodIcApplicationStoreData;
  }
  const pageTitle = queryString.parse(history.location.search).title;
  useEffect(() => {
    const currentUrl = window.location.href;
    const isNonDodIc = currentUrl.includes('/application/non-dod-ic');
    const pathname = window.location.pathname;
    const urlParams = new URLSearchParams(currentUrl);
    const reloadParam = urlParams.get('reload');
    // Check if the URL contains '/application/non-dod-ic'
    if (reloadParam) {
      const navigateAndReload = (url) => {
        window.location.href = url;
      };
      if(pathname === "/application"){
        navigateAndReload(`application?title=${pageTitle}`);
      } else if (pathname === "/application/spouse"){
        navigateAndReload(`spouse?title=${pageTitle}`);
      } else if (pathname === "/application/non-dod-ic"){
        navigateAndReload(`non-dod-ic?title=${pageTitle}`);
      }
    }
    if (pageTitle === "personal_info-basic") {
    try {
        setInputData(
          [
            {
              id: 0,
              className: "nickName",
              inputType: "text",
              userField: "nickName",
              placeHolder: "Preferred Name/Nickname (Optional)",
              required: false,
              value: storeData && storeData.nickName !== null && storeData.nickName !== undefined ? storeData.nickName : ''
            },
            {
              id: 1,
              className: "dateOfBirth",
              placeHolder: "Date Of Birth (MM/DD/YYYY)",
              userField: "dateOfBirth",
              inputType: "date",
              type: "date",
              title: "Date of Birth",
              required: true,
              value: storeData && storeData.dateOfBirth !== null && storeData.dateOfBirth !== undefined ? storeData.dateOfBirth : ''
            },
            {
              id: 2,
              className: "phoneNumber",
              inputType: "text",
              userField: "phoneNumber",
              placeHolder: "Phone Number",
              title: "Phone Number",
              required: true,
              value: `${storeData?.phoneNumber ?? ""}`
            },
            {
              id: 3,
              className: "gender",
              userField: "gender",
              inputType: "select",
              placeHolder: "Gender",
              title: "Gender",
              options: ["Female", "Male", "Other", "Prefer Not To Say"],
              required: true,
              value: storeData && storeData.gender !== null && storeData.gender !== undefined ? storeData.gender : ''
            },
            {
              id: 4,
              className: "ethnicity",
              userField: "ethnicity",
              inputType: "select",
              placeHolder: "Ethnicity",
              title: "Ethnicity",
              options: [
                "Black",
                "Latino",
                "White / Caucasian",
                "Asian",
                "Pacific Islander",
                "Other",
                "American Indian",
                "Prefer Not To Say"
              ],
              required: true,
              value: `${storeData && storeData.ethnicity !== null && storeData.ethnicity !== undefined ? storeData.ethnicity : ''}`
            },
            {
              id: 5,
              className: "maritalStatus",
              userField: "marital_status",
              inputType: "select",
              placeHolder: "Martial Status",
              title: "Marital Status",
              options: ["Married", "Not Married", "Prefer Not To Say"],
              required: true,
              value: `${storeData && storeData.marital_status !== null && storeData.marital_status !== undefined ? storeData.marital_status : ''}`
            },
            {
              id: 6,
              className: "linkedInUrl",
              userField: "linkedin_url",
              inputType: "text",
              placeHolder: "LinkedIn URL (Please provide or write not available)",
              title: "LinkedInUrl",
              required: true,
              value: `${storeData && storeData.linkedin_url !== null ? storeData.linkedin_url : ''}`
            },
            {
              id: 7,
              placeHolder: "hearAboutCommit",
              className: "howDidYouHear",
              userField: "hearAboutCommit",
              inputType: "select",
              options: [
                { value: "Friend/Colleague (Please provide name)", showOtherInput: true },
                { value: "Online", showOtherInput: false },
                { value: "Social Media", showOtherInput: false },
                {
                  value: "Installation/Military Program Referral (Please provide name)",
                  showOtherInput: true
                },
                { value: "Unit Referral (Please provide name)", showOtherInput: true },
                { value: "Wounded Warriors Project", showOtherInput: false },
                {
                  value: "Other Veteran Service Organization (Please provide name)",
                  showOtherInput: true
                },
                { value: "Other (Please describe below)", showOtherInput: true }
              ],
              title: "How did you hear about COMMIT?",
              required: true,
              value: `${storeData && storeData.hearAboutCommit !== null ? storeData.hearAboutCommit : ''}`
            },
          ]
          
        )
              } catch (e) {
        
      }
    } else if (pageTitle === "personal_info-address"){
      setInputData(
        [
          {
            id: 0,
            className: "streetAddress",
            userField: "address.streetAddress",
            inputType: "text",
            placeHolder: "Street Address",
            title: "Street Address",
            required: true,
            value: `${storeData && storeData.address && storeData.address.streetAddress !== null && storeData.address.streetAddress !== undefined ? storeData.address.streetAddress : ''}`
          },
          {
            id: 1,
            className: "city",
            userField: "address.city",
            placeHolder: "City",
            inputType: "text",
            title: "City",
            required: true,
            value: `${storeData && storeData.address && storeData.address.city !== null && storeData.address.city !== undefined ? storeData.address.city : ''}`
          },
          {
            id: 2,
            className: "state",
            userField: "address.state",
            placeHolder: "State",
            inputType: "select",
            options: [],
            title: "State",
            required: true,
            value: `${storeData && storeData.address && storeData.address.state !== null && storeData.address.state !== undefined ? storeData.address.state : ''}`
          },
          {
            id: 3,
            className: "zipCode",
            userField: "address.zipCode",
            placeHolder: "Zip Code",
            inputType: "text",
            title: "Zip Code",
            required: true,
            value: `${storeData && storeData.address && storeData.address.zipCode !== null && storeData.address.zipCode !== undefined ? storeData.address.zipCode : ''}`
          },
          {
            id: 4,
            className: "country",
            userField: "address.country",
            placeHolder: "Country",
            inputType: "select",
            options: [],
            title: "Country",
            required: true,
            value: `${storeData && storeData.address && storeData.address.country !== null && storeData.address.country !== undefined ? storeData.address.country : ''}`
          },
          {
            id: 5,
            className: "willLocate",
            userField: "address.willLocate",
            inputType: "select",
            placeHolder: "Are you planning to or willing to relocate? Y/N",
            title: "Are you planning to or willing to relocate?",
            className: "relocation",
            options: [
              { id: 0, value: "No" },
              { id: 1, value: "Yes" }
            ],
            required: true,
            value: `${storeData && storeData.address && storeData.address.willLocate !== null && storeData.address.willLocate !== undefined ? storeData.address.willLocate : ''}`
          }
        ]        
      )
    } else if (pageTitle === "personal_info-timeline"){
      setInputData(
        [
          {
            id: 7,
            userField: "timeline",
            inputType: "select",
            placeHolder: "",
            title: "Timeline to transition?",
            options: [
              { id: "0-6 months", value: "0-6 months" },
              { id: "6-12 months", value: "6-12 months" },
              { id: "12-24 months", value: "12-24 months" },
              { id: "24+ months", value: "24+ months" },
              { id: "Unsure", value: "Unsure" }
            ],
            value: `${storeData && storeData.timeline !== null && storeData.timeline !== undefined ? storeData.timeline : ''}`,
            required: true,
          }
        ]        
      )
    } else if (pageTitle === "spouse_info-active_duty"){
      setInputData(
        [
          {
            id: 0,
            userField: "active_duty",
            inputType: "select",
            placeHolder: "",
            title: "Seeking/receiving services or has your spouse previously?",
            className: "active_duty",
            options: [
              { id: 1, value: "Yes" },
              { id: 0, value: "No" }
            ],
            value: `${storeData && storeData.active_duty !== null && storeData.active_duty !== undefined ? storeData.active_duty : ''}`,
            required: true,
          }
        ]        
      )
    } else if (pageTitle === "spouse_info-service_member_name"){
      setInputData(
        [
          {
            id: 0,
            userField: "service_member_name",
            inputType: "text",
            placeHolder: "Service Member Name",
            title: "Service Member Name",
            value: `${storeData && storeData.service_member_name !== null && storeData.service_member_name !== undefined ? storeData.service_member_name : ''}`,
            required: true
          }
        ]        
      )
    } else if (pageTitle === "military-initial"){
      setInputData([
        {
          id: 0,
          userField: "serve_millitary",
          inputType: "select",
          placeHolder: "",
          title: "Did you serve in the Military?",
          className: "serve_millitary",
          required: true,
          options: [
            { id: 1, value: "Yes" },
            { id: 0, value: "No" }
          ],
          value: `${storeData && storeData.serve_millitary !== null && storeData.serve_millitary !== undefined ? storeData.serve_millitary.toString() : ""}`
        }
      ]);      
    } else if (pageTitle === "biography"){
      try {
      setInputData(
        [
          {
            id: 'biography',
            inputType: "longText",
            defaultValue: `A good biography should be approximately 150 words in length and may include the duration of your service, key leadership positions, future career interests, your current timeline for transition, what you would like to get out of the COMMIT experience, and any personal details you would like to highlight. Your bio will be shared with COMMIT mentors. Please write in the third person and avoid references to specific ranks, awards, or units. (minimum 300 characters and maximum 1000 characters)`,
            placeholder: `Write your biography`,
            charLimit: 1000,
            userField: "biography.biography",
            title: `Biograpy`,
            required: true,
            value: `${storeData && storeData.biography && storeData.biography.biography !== null ? storeData.biography.biography : ""}`
          }
        ]
      )
      } catch (e) {
        
      }
    } else if (pageTitle === "biography-spouse_impact"){
      setInputData(
        [
          {
            id: 'biography',
            inputType: "longText",
            defaultValue: `A good biography should be approximately 150 words in length and may include the duration of your service, key leadership positions, future career interests, your current timeline for transition, what you would like to get out of the COMMIT experience, and any personal details you would like to highlight. Your bio will be shared with COMMIT mentors. Please write in the third person and avoid references to specific ranks, awards, or units. (minimum 300 characters and maximum 1000 characters)`,
            placeholder: `How do you believe your life and/or your career has been impacted by being a military family?`,
            charLimit: 1000,
            userField: "biography.impact",
            title: `Biograpy`,
            required: true,
            value: `${storeData && storeData.biography && storeData.biography.impact !== null ? storeData.biography.impact : ""}`
          }
        ]
      )
    } else if (pageTitle === "biography-career_move"){
      setInputData(
        [
          {
            id: 'biography',
            inputType: "longText",
            defaultValue: `What inspired you to apply for the Family Transition Program`,
            placeholder: `How do you believe your spouse's next career move will impact the decisions you make for your future purpose work or career?`,
            charLimit: 1000,
            userField: "biography.next_impact",
            title: `Biograpy`,
            required: true,
            value: `${storeData && storeData.biography && storeData.biography.next_impact !== null ? storeData.biography.next_impact : ""}`
          }
        ]
      )
    } else if (pageTitle === "biography-inspiration"){
      setInputData(
        [
        {
          id: 'biography',
          inputType: "longText",
          defaultValue: `What inspired you to apply for the Family Transition Program`,
          placeholder: `What inspired you to apply for the Family Transition Program`,
          charLimit: 1000,
          userField: "biography.inspiration",
          title: `Biograpy`,
          required: true,
          value: `${storeData && storeData.biography && storeData.biography.inspiration !== null ? storeData.biography.inspiration : ""}`
        }
      ]
      )
    } else if (pageTitle === "biography-challenge"){
      setInputData(
        [
          {
            id: 'biography',
            inputType: "longText",
            defaultValue: `A good biography should be approximately 150 words in length and may include the duration of your service, key leadership positions, future career interests, your current timeline for transition, what you would like to get out of the COMMIT experience, and any personal details you would like to highlight. Your bio will be shared with COMMIT mentors. Please write in the third person and avoid references to specific ranks, awards, or units. (minimum 300 characters and maximum 1000 characters)`,
            placeholder: `What do you see as your biggest challenges to this next move?`,
            charLimit: 1000,
            userField: "biography.challenge",
            title: `Biograpy`,
            required: true,
            value: `${storeData && storeData.biography && storeData.biography.challenge !== null ? storeData.biography.challenge : ""}`
          }
        ]
      )
    } else if (pageTitle === "biography-military_life"){
      setInputData(
        [
          {
            id: 'biography',
            inputType: "longText",
            defaultValue: `A good biography should be approximately 150 words in length and may include the duration of your service, key leadership positions, future career interests, your current timeline for transition, what you would like to get out of the COMMIT experience, and any personal details you would like to highlight. Your bio will be shared with COMMIT mentors. Please write in the third person and avoid references to specific ranks, awards, or units. (minimum 300 characters and maximum 1000 characters)`,
            placeholder: `What about your military life would you like to carry forward?`,
            charLimit: 1000,
            userField: "biography.military_life",
            title: `Biograpy`,
            required: true,
            value: storeData && storeData.biography && storeData.biography.military_life !== null ? storeData.biography.military_life : ""
          }
        ]
      )
    } else if (pageTitle === "biography-top_priorities"){
      setInputData(
        [
          {
            id: 0,
            inputType: "longText",
            userField: "biography.priorities",
            title:
              "What are your top five priorities for your first year out of uniform?",
            placeholder: "What are your top five priorities?",
            required: true,
            value: `${storeData && storeData.biography && storeData.biography.priorities !== null ? storeData.biography.priorities : ""}`
          }
      ]
      )
    } else if (pageTitle === "biography-motivates"){
      setInputData(
        [
          {
            id: 'biography',
            inputType: "longText",
            defaultValue: `A good biography should be approximately 150 words in length and may include the duration of your service, key leadership positions, future career interests, your current timeline for transition, what you would like to get out of the COMMIT experience, and any personal details you would like to highlight. Your bio will be shared with COMMIT mentors. Please write in the third person and avoid references to specific ranks, awards, or units. (minimum 300 characters and maximum 1000 characters)`,
            placeholder: `What motivates you?`,
            charLimit: 1000,
            userField: "biography.motivations",
            title: `Biograpy`,
            required: true,
            value: `${storeData && storeData.biography && storeData.biography.motivations !== null ? storeData.biography.motivations : ""}`
          }
        ]
      )
    } else if (pageTitle === "biography-careers_purpose"){
      setInputData(
        [
          {
            id: 'biography',
            inputType: "longText",
            defaultValue: `What inspired you to apply for the Family Transition Program`,
            placeholder: `What is your Career or Life Purpose?`,
            charLimit: 1000,
            userField: "biography.careers_purpose",
            title: `Biograpy`,
            required: true,
            value: `${storeData && storeData.biography && storeData.biography.careers_purpose !== null ? storeData.biography.careers_purpose : ""}`
          }
        ]
      )
    } else if (pageTitle === "career-strengths"){
      setInputData(
        [
          {
            id: 'biography',
            inputType: "longText",
            defaultValue: `A good biography should be approximately 150 words in length and may include the duration of your service, key leadership positions, future career interests, your current timeline for transition, what you would like to get out of the COMMIT experience, and any personal details you would like to highlight. Your bio will be shared with COMMIT mentors. Please write in the third person and avoid references to specific ranks, awards, or units. (minimum 300 characters and maximum 1000 characters)`,
            placeholder: `What strengths and skills will you bring to your next career/ purpose work?`,
            charLimit: 1000,
            userField: "biography.strengths_skills",
            title: `Biograpy`,
            required: true,
            value: `${storeData && storeData.biography && storeData.biography.strengths_skills !== null ? storeData.biography.strengths_skills : ""}`
          }
        ]
      )
    } else if (pageTitle === "career-preference"){
      setInputData(
        [
          {
            id: 0,
            className: "time_commit",
            inputType: "select",
            userField: "career.time_commit",
            title: "Able to commit 3-6 hours a month to this program?",
            required: true,
            value: `${storeData && storeData.career && storeData.career.time_commit !== null ? storeData.career.time_commit : ""}`,
            options: ["Yes", "No"]
          },
          {
            id: 1,
            className: "share_consent",
            inputType: "select",
            userField: "career.share_consent",
            title: "Share name and email with other participants for networking?",
            required: true,
            value: `${storeData && storeData.career && storeData.career.share_consent !== null ? storeData.career.share_consent : ""}`,
            options: ["Yes", "No"]
          },
          {
            id: 2,
            className: "class_preference",
            inputType: "select",
            userField: "career.class_preference",
            title: "Class Preference (typically a Thursday)",
            required: true,
            value: `${storeData && storeData.career && storeData.career.class_preference !== null ? storeData.career.class_preference : ""}`,
            options: ["Thursday 12pm Eastern Time", "Wednesday 11am Pacific Time"]
          }
        ]        
      )
    } else if (pageTitle === "career-misc"){
      setInputData(
        [
          {
            id: 0,
            className: "next_step_confidence",
            inputType: "select",
            userField: "career.next_step_confidence",
            title: "Confident about next steps in career or purpose work",
            required: true,
            value: `${storeData && storeData.career && storeData.career.next_step_confidence !== null ? storeData.career.next_step_confidence : ""}`
          },
          {
            id: 1,
            className: "lifestyle_options",
            inputType: "select",
            userField: "career.lifestyle_options",
            title: "Understand host of possible options and lifestyle options available",
            required: true,
            value: `${storeData && storeData.career && storeData.career.lifestyle_options !== null ? storeData.career.lifestyle_options : ""}`
          },
          {
            id: 2,
            className: "adequate_information",
            inputType: "select",
            userField: "career.adequate_information",
            title: "Have adequate information to navigate next life goal or career move",
            required: true,
            value: `${storeData && storeData.career && storeData.career.adequate_information !== null ? storeData.career.adequate_information : ""}`
          },
          {
            id: 3,
            className: "network",
            inputType: "select",
            userField: "career.network",
            title: "Know how to leverage your network",
            required: true,
            value: `${storeData && storeData.career && storeData.career.network !== null ? storeData.career.network : ""}`
          },
          {
            id: 4,
            className: "apply_skills",
            inputType: "select",
            userField: "career.apply_skills",
            title: "Know how to apply my skills and experience to future opportunities",
            required: true,
            value: `${storeData && storeData.career && storeData.career.apply_skills !== null ? storeData.career.apply_skills : ""}`
          }
        ]        
      )
    } else if (pageTitle === "submission"){
      setInputData(
        [
          {
            id: 0,
            inputType: "longText",
            userField: "submission.extraInfo",
            title:
              "Our services are highly individualized and we would like to better understand you. Please use the following space to add additional information. Some examples are why are you applying to COMMIT, what motivates you, what do you want to carry forward or leave behind from your military life?",
            required: true,
          }
        ]       
      )
    } else if (pageTitle === "career-career_setting"){
          setInputData(
            [
              {
                userField: "career.career_setting",
                value: `${storeData && storeData.career && storeData.career.career_setting !== null ? storeData.career.career_setting : ""}`,
                required: true
              }
            ]
          )
    } else if (pageTitle === "transition-goals"){
      setInputData([
        {
          userField: "transition.goals",
          value: `${storeData && storeData.transition && storeData.transition.goals !== null ? storeData.transition.goals : ""}`,
          required: true,
        }
      ])
    } else if (pageTitle === "relocation"){
      setInputData(
        [
          {
            country1: {
              userField: "relocations.country1",
              placeHolder: "Country",
              inputType: "select",
              options: [],
              title: "Country",
              className: "select-input",
              disabled: true,
              value: `${storeData && storeData.relocations && storeData.relocations.country1 !== null ? storeData.relocations.country1 : ""}`,
              required:true,
            },
            state1: {
              userField: "relocations.state1",
              placeHolder: "State",
              title: "State",
              inputType: "select",
              options: [],
              className: "select-input",
              disabled: true,
              value: `${storeData && storeData.relocations && storeData.relocations.state1 !== null ? storeData.relocations.state1 : ""}`,
              required:true,
            },
            city1: {
              userField: "relocations.city1",
              placeHolder: "City",
              title: "City",
              inputType: "select",
              options: [],
              className: "select-input",
              disabled: true,
              value: `${storeData && storeData.relocations && storeData.relocations.city1 !== null ? storeData.relocations.city1 : ""}`
            },
            country2: {
              userField: "relocations.country2",
              placeHolder: "Country",
              inputType: "select",
              options: [],
              title: "Country",
              className: "select-input",
              disabled: true,
              value: `${storeData && storeData.relocations && storeData.relocations.country2 !== null ? storeData.relocations.country2 : ""}`,
              required:true,
            },
            state2: {
              userField: "relocations.state2",
              placeHolder: "State",
              title: "State",
              inputType: "select",
              className: "select-input",
              options: [],
              disabled: true,
              value: `${storeData && storeData.relocations && storeData.relocations.state2 !== null ? storeData.relocations.state2 : ""}`,
              required:true,
            },
            city2: {
              userField: "relocations.city2",
              placeHolder: "City",
              title: "City",
              inputType: "select",
              options: [],
              className: "select-input",
              disabled: true,
              value: `${storeData && storeData.relocations && storeData.relocations.city2 !== null ? storeData.relocations.city2 : ""}`
            },
            country3: {
              userField: "relocations.country3",
              placeHolder: "Country",
              inputType: "select",
              options: [],
              title: "Country",
              className: "select-input",
              disabled: true,
              value: `${storeData && storeData.relocations && storeData.relocations.country3 !== null ? storeData.relocations.country3 : ""}`,
              required:true,
            },
            state3: {
              userField: "relocations.state3",
              placeHolder: "State",
              title: "State",
              inputType: "select",
              options: [],
              className: "select-input",
              disabled: true,
              value: `${storeData && storeData.relocations && storeData.relocations.state3 !== null ? storeData.relocations.state3 : ""}`,
              required:true,
            },
            city3: {
              userField: "relocations.city3",
              placeHolder: "City",
              title: "City",
              inputType: "select",
              options: [],
              className: "select-input",
              disabled: true,
              value: `${storeData && storeData.relocations && storeData.relocations.city3 !== null ? storeData.relocations.city3 : ""}`
            }
          }
        ]
      )
    } else if (pageTitle === "military-basic") {
      setInputData([
        {
          id: 0,
          userField: "military.branch",
          className: "branch",
          inputType: "select",
          options: [
            { id: 0, value: "Air Force" },
            { id: 1, value: "Army" },
            { id: 5, value: "Coast Guard" },
            { id: 2, value: "Marines" },
            { id: 4, value: "National Guard" },
            { id: 3, value: "Navy" },
            { id: 7, value: "Space Force" },
            { id: 6, value: "Civilian" },
          ],
          placeHolder: "Branch",
          title: "What is your branch of service?",
          required: true,
          value: `${storeData && storeData.military && storeData.military.branch !== null ? storeData.military.branch : ""}`,
        },
        {
          id: 1,
          userField: "military.lastRank",
          placeHolder: "Final Rank",
          className: "rank",
          inputType: "select",
          options: [
            { id: 0, value: "E-1" },
            { id: 1, value: "E-2" },
            { id: 2, value: "E-3" },
            { id: 3, value: "E-4" },
            { id: 4, value: "E-5" },
            { id: 5, value: "E-6" },
            { id: 6, value: "E-7" },
            { id: 7, value: "E-8" },
            { id: 8, value: "E-9" },
            { id: 10, value: "O-1" },
            { id: 12, value: "O-2" },
            { id: 13, value: "O-3" },
            { id: 14, value: "O-4" },
            { id: 16, value: "O-5" },
            { id: 17, value: "O-6" },
            { id: 18, value: "O-7" },
            { id: 19, value: "O-8" },
            { id: 20, value: "O-9" },
            { id: 21, value: "O-10" },
            { id: 10, value: "W-1" },
            { id: 11, value: "W-2" },
            { id: 12, value: "W-3" },
            { id: 13, value: "W-4" },
            { id: 14, value: "W-5" },
            ...(isNonDodIc ? [{ id: 22, value: "Non-DOD-IC" }] : [])
          ],
          title: "What was your final rank?",
          required: true,
          value: `${storeData && storeData.military && storeData.military.lastRank !== null ? storeData.military.lastRank : ""}`
        },
        {
          id: 2,
          userField: "military.status",
          className: "status",
          inputType: "select",
          placeHolder: "Status",
          options: [
            { id: 0, value: "Active Duty" },
            { id: 1, value: "National Guard" },
            { id: 2, value: "Active Guard/ Reserve (>180 days)" },
            { id: 3, value: "Reserve" },
            { id: 4, value: "Retired" },
            { id: 5, value: "Separated (Voluntary)" },
            {
              id: 6,
              value: "Medical Separation or Medical Retirement (Pending or Complete)"
            },
            { id: 7, value: "Other Than Honorable Discharge" },
            { id: 8, value: "Dishonorable Discharge" },
            { id: 8, value: "Bad Conduct Discharge" },
            { id: 8, value: "Dismissed" }
          ],
          title: "What is your military status?",
          required: true,
          value: `${storeData && storeData.military && storeData.military.status !== null ? storeData.military.status : ""}`
        },
        {
          id: 3,
          userField: "military.years_of_service",
          className: "yearsOfService",
          inputType: "numberInput",
          placeHolder: "Years of Service (Including years until separation date)",
          title: "Years of Service",
          required: true,
          value: `${storeData && storeData.military && storeData.military.years_of_service !== null ? storeData.military.years_of_service : ""}`
        },
        {
          id: 4,
          userField: "military.retirementDate",
          className: "sepDate",
          shrink: true,
          helperText:
            "Please include a projected date if you are still on active duty",
          placeHolder: "What is your separation or retirement date? (MM/DD/YYYY)",
          type: "date",
          inputType: "date",
          title:
            "What is your separation or retirement date? Please include a projected date if you are still active duty.",
          required: true,
          value: `${storeData && storeData.military && storeData.military.retirementDate !== null ? storeData.military.retirementDate : ""}`
        },
        {
          id: 5,
          userField: "military.specialty",
          className: "specialty",
          inputType: "text",
          placeHolder: "Military Occupation (e.g. Helicopter Pilot)",
          title: "Military Specialty",
          required: true,
          value: `${storeData && storeData.military && storeData.military.specialty !== null ? storeData.military.specialty : ""}`
        },
        {
          id: 6,
          userField: "military.lastDutyPosition",
          inputType: "text",
          className: "duty",
          placeHolder: "Last Duty Position",
          title: "What was the last duty position you held?",
          required: true,
          value: `${storeData && storeData.military && storeData.military.lastDutyPosition !== null ? storeData.military.lastDutyPosition : ""}`
        },
        {
          id: 7,
          userField: "military.sof",
          className: "sofSelect",
          inputType: "select",
          placeHolder:
            "Was the last duty position a unit within the USSOCOM enterprise?",
          title: "Was the last duty position a unit within the USSOCOM enterprise?",
          options: [
            { id: 0, value: "No" },
            { id: 1, value: "Yes" }
          ],
          required: true,
          value: `${storeData && storeData.military && storeData.military.sof !== null ? storeData.military.sof : ""}`
        },
        {
          id: 8,
          className: "sofUnit invisible",
          userField: "military.sofUnit",
          inputType: "select",
          placeHolder: "SOF Unit",
          title: "SOF Unit",
          options: [],
          value: `${storeData && storeData.military && storeData.military.sofUnit !== null ? storeData.military.sofUnit : ""}`
        }
      ]
      )
    } else if (pageTitle === "transition-challenges"){
      setInputData(
        [
          {
            id: 0,
            userField: "transition.challenges",
            inputType: "longText",
            placeholder:
              "What do you see as the biggest challenges in your transition?",
              required: true,
              value: `${storeData && storeData.transition && storeData.transition.challenges !== null ? storeData.transition.challenges : ""}`
          },
        ]
      )
    } else if (pageTitle === "career-interested_industries"){
      setInputData(
        [
          {
            id: 0,
            userField: "interests",
            inputType: "buttonSelection",
            title: `What industries are you interested in?`,
            required: true,
            value: storeData && storeData.interests && storeData.interests.length > 0
            ? storeData.interests.map(obj => ({ id: obj.id, value: obj.name, isSelected: true }))
            : [],
          },
          {
            id: 1,
            userField: "career.otherInterest",
            className: "career.otherInterest",
            inputType: "buttonSelection",
            title: `What industries are you interested in?`,
            required: false,
            value: `${storeData && storeData.career && storeData.career.otherInterest !== null && storeData.career.otherInterest !== undefined ? storeData.career.otherInterest : ""}`
          }
        ]
      )
    }
    // dispatch(Actions.setInputData(null));
    // setInputData(null);
   }, [pageTitle])

  useEffect(() => {
    const gradualScrollToTop = () => {
      // Mobile selects a different element
      const position =
        window.innerWidth < 600
          ? document.getElementById("Content-Container")
          : document.getElementById("applicationScrollContainer")
      if (position.scrollTop) {
        position.scrollBy(0, -Math.max(1, Math.floor(position.scrollTop / 10)))
        setTimeout(() => gradualScrollToTop(), 10)
      }
    }

    gradualScrollToTop()
  }, [history.location.search])

  const Input = inputs[pageTitle]

  if (!Input) {
    return (
      <div style={{ textAlign: "center" }}>
        No component matches <code>{pageTitle}</code>.
      </div>
    )
  }
  const handleInputChange = (inputData) => {
    try {
      const currentUrl = window.location.href;
      const hasNonDodIcPath = currentUrl.includes("/application/non-dod-ic");
      const hasTransitionGoalsTitle = currentUrl.includes("title=transition-goals");
  
      if (hasNonDodIcPath && !hasTransitionGoalsTitle) {
        dispatch(Actions.setNonDodIcInputData(inputData));
      } else {
        dispatch(Actions.setInputData(inputData));
      }
      setInputData(inputData);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Input  
      changeHandler={handleInputChange}
      renderComponent={({ inputComponent }) => {
        return (
          <>
            <div
              className="applicationEditorContainer"
              style={{
                marginRight: "auto",
                marginLeft: "auto"
              }}
            >
              {inputComponent}
            </div>
          </>
        )
      }}
    />
  )
}

UserFieldEditor.propTypes = {
  inputs: PropTypes.object
}

export default withRouter(UserFieldEditor)
