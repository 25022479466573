import { profileSteps } from "../Redux/Reducers/Application/profileMetadata"
import { applicationSteps } from "../Redux/Reducers/Application/applicationMetadata"
import { spouseApplicationSteps } from "../Redux/Reducers/SpouseApplication/spouseApplicationMetadata"
import { spouseProfileSteps } from "../Redux/Reducers/SpouseApplication/spouseProfileMetadata"
import { NonDodIcProfileSteps } from "../Redux/Reducers/NonDodIcApplication/NonDodIcProfileMetadata"
import { NonDodIcApplicationSteps } from "../Redux/Reducers/NonDodIcApplication/NonDodIcApplicationMetadata"

const constants = {
  HOST: process.env.REACT_APP_API_URL,
  APPLICATION_PROCESS_FIRST_PAGE: applicationSteps[0].stepId,
  APPLICATION_PROCESS_LAST_PAGE:
    applicationSteps[applicationSteps.length - 1].stepId,
  PROFILE_FIRST_PAGE: profileSteps[0].stepId,
  PROFILE_LAST_PAGE: profileSteps[profileSteps.length - 1].stepId,
  SPOUSE_PROFILE_LAST_PAGE: spouseProfileSteps[spouseProfileSteps.length - 1].stepId,

  // Non-DOD IC specific constants
  NON_DOD_IC_APPLICATION_PROCESS_FIRST_PAGE: NonDodIcApplicationSteps[0].stepId,
  NON_DOD_IC_APPLICATION_PROCESS_LAST_PAGE: NonDodIcApplicationSteps[NonDodIcApplicationSteps.length - 1].stepId,
  NON_DOD_IC_PROFILE_FIRST_PAGE: NonDodIcProfileSteps[0].stepId,
  NON_DOD_IC_PROFILE_LAST_PAGE: NonDodIcProfileSteps[NonDodIcProfileSteps.length - 1].stepId,

  PYP_COURSE_USI: "1",
  DL_URL_QUALIFIER: "digitalLibrary",
  PYP_URL_QUALIFIER: "workshop",
  HOME_URL_QUALIFIER: "home",
  ROLLBAR_KEY: process.env.REACT_APP_ROLLBAR_KEY,
  DONATE_URL: "https://www.commitfoundation.org/donation",
  DEFAULT_API_TIMEOUT_MS: 20000,
  SPOUSE_APPLICATION_PROCESS_FIRST_PAGE: spouseApplicationSteps[0].stepId
}

export default constants
