import { clear } from "redux-localstorage-simple"
import Rollbar from "rollbar"
import types from "../types"
import * as reducerUtils from "../../Utils/reducerUtils"
import * as actionUtils from "../../Utils/actionUtils"
import constants from "../../../Constants"
import fetch from "../../../Utils/fetch"

const rollbar = new Rollbar({
  accessToken: constants.ROLLBAR_KEY,
  captureUncaught: false,
  captureUnhandledRejections: false
})

export const incrementGuide = () => {
  return async (dispatch, getState) => {
    try {
      const { guide, currentStepId } = getState().instructionalGuide

      dispatch({
        type: types.INCREMENT_INSTRUCTION_STEP
      })

      if (currentStepId + 1 === guide.length) {
        dispatch({
          type: types.GUIDE_COMPLETE
        })

        await dispatch(
          fetch(`/updateUserGuide`, {
            type: "POST",
            data: {
              requestPayload: { has_completed_application_guide: true }
            }
          })
        )

        const res = await dispatch(
          fetch(`/user`, {
            type: "GET"
          })
        )

        dispatch({
          type: types.FETCHED_USER,
          user: res.data[0]
        })
      }
    } catch (errorMessage) {
      console.log(errorMessage)
    }
  }
}

// export const instantiateRollbar = () => {
//   return async (dispatch, getState) => {
//     try {
//       const rollbar = new Rollbar({
//         accessToken: constants.ROLLBAR_KEY,
//         captureUncaught: false,
//         captureUnhandledRejections: false
//       })
//       dispatch({
//         type: types.SETUP_ROLLBAR,
//         data: {
//           rollbar
//         }
//       })
//     } catch (errorMessage) {
//       console.log(errorMessage)
//     }
//   }
// }

export const reportError = (info) => {
  return (dispatch, getState) => {
    try {
      rollbar && rollbar.info(`${info}, reportError`)
    } catch (e) {
      console.log(e)
    }
  }
}

export const setLoginRedirect = (url) => {
  return (dispatch, getState) => {
    try {
      dispatch({
        type: types.SET_LOGIN_REDIRECT,
        data: {
          url
        }
      })
    } catch (e) {
      console.log(e)
    }
  }
}

export const refreshLogin = (accountData, history) => {
  return async (dispatch, getState) => {
    const { refreshToken } = getState().api
    const tokenRes = await dispatch(
      fetch(`/refreshLogin`, {
        type: "POST",
        data: { requestPayload: { refreshToken } }
      })
    )
    dispatch({
      type: types.REFRESHED_TOKEN,
      data: {
        token: tokenRes.data.token,
        refreshToken: tokenRes.data.refreshToken
      }
    })
  }
}

export const createAccount = (accountData, history) => async (dispatch) => {
  try {
    dispatch({
      type: types.CREATING_ACCOUNT
    });

    const processedData = accountData.reduce((inputsAcum, input) => {
      if (!input.value || input.id === "confirmPassword") return inputsAcum;

      return {
        ...inputsAcum,
        [input.id]: input.value
      };
    }, {});

    const { data: userData } = await dispatch(
      fetch(`/user`, {
        type: "POST",
        data: { requestPayload: { ...processedData } }
      })
    );
    
    dispatch({
      type: types.LOGIN,
      user: userData
    });

    dispatch({
      type: types.SET_API_KEY,
      apiToken: userData.apiToken,
      refreshToken: userData.refreshToken
    });

    // Routing logic based on the value of `isSpouse`
    if (processedData.isSpouse === 1) {
      history.push(`/application/spouse?title=${constants.SPOUSE_APPLICATION_PROCESS_FIRST_PAGE}`);
    } else if (processedData.isSpouse === 2) {
      history.push(`/application/non-dod-ic?title=${constants.NON_DOD_IC_APPLICATION_PROCESS_FIRST_PAGE}`);
    } else {
      history.push(`/application?title=${constants.APPLICATION_PROCESS_FIRST_PAGE}`);
    }

  } catch (error) {
    if (error.response?.data?.message) {
      dispatch({
        type: "EXCEPTION",
        data: {
          message: error.response.data.message,
          type: "User"
        }
      });
    } else {
      dispatch({
        type: "EXCEPTION",
        data: {
          message: error.toString(),
          type: "system"
        }
      });

      if (rollbar) {
        rollbar.error(
          `${error.response ? error.response.data : error} (${accountData.email}), Create Account`
        );
      }
    }
  }
};

export const addInterval = (interval) => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.ADD_INTERVAL,
      data: interval
    })
  }
}

export const resetPassword = (password, history, params) => {
  return async (dispatch) => {
    try {
      await dispatch(
        fetch(`/resetPassword`, {
          type: "POST",
          data: {
            token: `Bearer ${params.token}`,
            requestPayload: { password }
          }
        })
      )
      history.push("/account/login")
    } catch (error) {
      error.response.status === 500 || error.response.status === 404
        ? dispatch({
            type: "ERROR",
            data: {
              message: error.response.data
            }
          })
        : dispatch({
            type: "EXCEPTION",
            data: error.response.data
          })
    }
  }
}

export const resetErrors = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.RESET_ERRORS
      })
    } catch (data) {
      dispatch({
        type: types.ERROR,
        data: data.response
      })
    }
  }
}

export const logout = () => {
  return async (dispatch) => {
    try {
      // await dispatch(
      //   fetch(`/logout`, {
      //     type: "POST",
      //     data: {
      //       requestPayload: null
      //     }
      //   })
      // )
      clear()
      dispatch({
        type: types.LOGOUT
      })
      dispatch({
        type: types.CLEAR_API_KEY
      })
    } catch (data) {
      dispatch({
        type: types.ERROR,
        data: data.response
      })
    }
  }
}

export const sendPasswordResetEmail = (email, history) => {
  return async (dispatch) => {
    try {
      await dispatch(
        fetch(`/sendResetPasswordEmail`, {
          type: "POST",
          data: {
            requestPayload: { email }
          }
        })
      )
      history.push("/account/passwordResetSent")
    } catch ({ response }) {
      dispatch({
        type: "ERROR",
        data: response
      })
    }
  }
}

export const fetchUser = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.FETCHING_USER
      })

      const res = await dispatch(
        fetch(`/user`, {
          type: "GET"
        })
      )
      dispatch({
        type: types.FETCHED_USER,
        user: res.data[0]
      })
    } catch (e) {
      // if there's an error on fetch user, nothing in the application will likely work.
      // we should at least try to get the user to sign in with a cleared localstorage
      clear()
      dispatch({
        type: types.CLEAR_API_KEY
      })
      dispatch({
        type: types.ERROR,
        data: {
          redirectUri: "/account/login"
        }
      })
    }
  }
}

export const login = (loginCreds, history) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.LOGGING_IN
      })

      const { data: user } = await dispatch(
        fetch(
          `/login`,
          {
            type: "POST",
            data: { requestPayload: { ...loginCreds } }
          },
          {
            timeout: 8000
          }
        )
      )

      const { redirectUrl } = getState().user

      dispatch({
        type: types.SET_API_KEY,
        apiToken: user.apiToken,
        refreshToken: user.refreshToken
      })

      dispatch({
        type: types.LOGIN,
        user
      })

      if (user.has_submitted_application || user.role.id === 1) {
        history.push(redirectUrl || "/home")
        return
      }

      // Update to handle isSpouse value (0 for Veteran, 1 for Spouse, 2 for Non-DOD IC)
      let endpoint = '/application';
      let applicatonPath = '/application';
      if (user.is_spouse === 1) {
        endpoint = '/spouse-application';
        applicatonPath = '/application/spouse';
      } else if (user.is_spouse === 2) {
        endpoint = '/non-dod-ic-application';
        applicatonPath = '/application/non-dod-ic';
      }

      // Fetch application data based on user type
      const { data: applicationData } = await dispatch(
        fetch(endpoint, {
          type: "GET"
        })
      )

      const { applicationMetadata } = getState().application
      const { spouseApplicationMetadata } = getState().spouseApplication
      const updatedMetaData = reducerUtils.processNavOptionsForApplication(
        actionUtils.formatIncomingApplication(applicationData),  // Process the application data
        user.is_spouse === 1 ? spouseApplicationMetadata : applicationMetadata
      )
      const initialNavItem = updatedMetaData.find(
        (item) =>
          typeof item.filled === "boolean" && !item.filled && item.display
      )
      const initialStep = initialNavItem
        ? initialNavItem.steps.find((item) => !item.filled)
        : { stepId: "personal_info-basic" }

      history.push(`${applicatonPath}?title=${initialStep.stepId}`)
    } catch (error) {
      if (!error.response) {
        dispatch({
          type: "ERROR",
          data: {
            message: error.response ? error.response.data : error
          }
        })

        dispatch({
          type: types.CLEAR_API_KEY
        })

        dispatch({
          type: "EXCEPTION",
          data: {
            message: error.toString(),
            type: "system"
          }
        })

        rollbar &&
          rollbar.error(
            `Caught in login - ${
              error.response ? error.response.data : error
            } (${loginCreds.email}),`
          )
      } else {
        rollbar && rollbar.error(`Caught in login - ${"testing"}`)

        if (error.response && error.response.status > 400) {
          const errorData = error.response.data[0]
          const message = errorData
          if (
            message === "Cannot find user with provided email" ||
            message === "Invalid user password"
          ) {
            dispatch({
              type: "EXCEPTION",
              data: {
                message: "Invalid Credentials",
                type: "user"
              }
            })
          } else {
            dispatch({
              type: types.CLEAR_API_KEY
            })

            dispatch({
              type: "EXCEPTION",
              data: {
                message: error.response.data && error.response.data[0].message.toString(),
                type: "system"
              }
            })

            rollbar &&
              rollbar.error(
                `Caught in login - ${
                  error.response ? error.response.data : error
                } (${loginCreds.email}),`
              )
          }
        }
      }
    }
  }
}

export const retrieveUsers = (
  json,
  campaignAttendees,
  page = 1,
  limit = 20
) => {
  const blankUsers = []
  for (let i = 0; i < limit; i++) {
    blankUsers.push({})
  }
  return async (dispatch, getState) => {
    const { network, networkUsers } = getState()
    dispatch({
      type: types.FETCHING_SEARCH_USERS,
      data: { toggle: true }
    })
    dispatch({
      type: types.SET_SEARCH_USERS,
      data: {
        ...networkUsers,
        users: page === 1 ? blankUsers : networkUsers.users.concat(blankUsers)
      }
    })

    try {
      const userRes = await dispatch(
        fetch(`/searchVeterans`, {
          type: "POST",
          data: {
            requestPayload: {
              page,
              limit,
              ...json,
              filter_interests:
                (network.filter_interests && [network.filter_interests]) || "",
              campaignAttendees
            }
          }
        })
      )

      if (page === 1) {
        dispatch({
          type: types.SET_SEARCH_USERS,
          data: {
            users: userRes.data.data,
            page: userRes.data.page,
            total: userRes.data.total,
            lastPage: userRes.data.lastPage
          }
        })
      } else {
        dispatch({
          type: types.ADD_SEARCH_USERS,
          data: {
            page,
            limit,
            users: userRes.data.data,
            // page: userRes.data.page,
            total: userRes.data.total,
            lastPage: userRes.data.lastPage
          }
        })
      }

      dispatch({
        type: types.FETCHING_SEARCH_USERS,
        data: { toggle: false }
      })
    } catch (e) {}
  }
}

export const updateNetworkSearch = (newTerms) => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.UPDATE_SEARCH_PARAMS,
      data: {
        ...newTerms
      }
    })
  }
}

export const refreshNetworkSearch = (newTerms) => {
  const search_term = Object.keys(newTerms).filter((key) =>
    key.includes("search_")
  )
  const search_text = search_term && newTerms[search_term]
  const search_item =
    search_term && search_term[0] && search_term[0].replace("search_", "")
  return async (dispatch, getState) => {
    dispatch({
      type: types.UPDATE_SEARCH_PARAMS,
      data: {
        ...newTerms,
        search_text,
        search_item
      }
    })
  }
}

export const resetNetworkSearch = (newTerms) => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.RESET_SEARCH_PARAMS
    })
  }
}

export const uploadUserThumbnail = (profileImageData) => async (dispatch) => {
  dispatch({
    type: types.UPDATING_PROFILE_THUMBNAIL
  })

  try {
    await dispatch(
      fetch(`/userThumbnailUpload`, {
        type: "POST",
        data: {
          headers: {
            "Content-Type": `multipart/form-data;`,
            "Cache-Control": "no-cache"
          },
          requestPayload: profileImageData
        }
      })
    )

    const {
      data: [user]
    } = await dispatch(
      fetch(`/user`, {
        type: "GET"
      })
    )

    dispatch({
      type: types.FETCHED_USER,
      user: user || {}
    })
  } catch (error) {
    dispatch({
      type: "ERROR"
    })
  } finally {
    dispatch({
      type: types.UPDATED_PROFILE_THUMBNAIL
    })
  }
}

export const completeGuide = (GUIDE_USI, history, route) => {
  return async (dispatch, getState) => {
    try {
      await dispatch(
        fetch(`/userGuideCompletion`, {
          type: "POST",
          data: {
            requestPayload: {
              GUIDE_USI
            }
          }
        })
      )

      const userRes = await dispatch(
        fetch(`/user`, {
          type: "GET"
        })
      )

      dispatch({
        type: types.FETCHED_USER,
        user: userRes.data[0]
      })

      if (history) {
        history.push(route)
      }
    } catch (error) {
      dispatch({
        type: "ERROR"
      })
    }
  }
}

export const toggleModalCrop = (modal, image_for_cropping = null) => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.TOGGLE_MODAL_CROP,
      data: {
        modal: {
          cropProfile: modal
        },
        image_for_cropping
      }
    })
  }
}

export const toggleHasCheckedProfile = (hasChecked) => { //Currently not being used, but may be used in the future
  return async (dispatch, getState) => {
    dispatch({
      type: types.TOGGLE_HAS_CHECKED_PROFILE,
      data: hasChecked
    })
  }
}

export const toggleModal = ({
  modal_mode = false,
  modal_usi,
  title = "",
  text = ""
}) => {
  return async (dispatch) => {
    dispatch({
      type: types.TOGGLE_MODAL,
      data: {
        modal: {
          [modal_usi]: modal_mode
        },
        title,
        text
      }
    })
  }
}

export const fetchButtonAnalytics = (type, limit) => {
  return async (dispatch, getState) => {
    const analytics = await dispatch(
      fetch(
        `/analytics/buttons`,
        {
          type: "POST",
          data: {
            requestPayload: {
              type,
              limit
            }
          }
        },
        {
          timeout: 300000
        }
      )
    )

    dispatch({
      type: types.FETCHED_BUTTON_ANALYTICS,
      data: {
        analyticsData: analytics.data
      }
    })
  }
}

export const fetchVideoAnalytics = (
  type,
  limit,
  startConstraint,
  endConstraint
) => {
  return async (dispatch, getState) => {
    const analytics = await dispatch(
      fetch(
        `/analytics/videos`,
        {
          type: "POST",
          data: {
            requestPayload: {
              type,
              limit,
              startConstraint,
              endConstraint
            }
          }
        },
        {
          timeout: 300000
        }
      )
    )

    dispatch({
      type: types.FETCHED_VIDEO_ANALYTICS,
      data: {
        analyticsData: analytics.data
      }
    })
  }
}

// export const fetchBuiltAnalytics = (reportFilters) => {
//   return async (dispatch, getstate) => {
//     dispatch({
//       type: types.FETCHING_ANALYTICS
//     })

//     const builtAnalytics = await dispatch(
//       fetch("/analyticsReportBuilder", {
//         type: "POST",
//         data: {
//           requestPayload: {
//             reportFilters
//           }
//         }
//       })
//     )

//     dispatch({
//       type: types.FETCHED_GOOGLE_ANALYTICS,
//       data: {
//         analyticsData: builtAnalytics.data
//       }
//     })
//   }
// }

export const fetchAnalytics = (startDate, endDate) => {
  return async (dispatch, getstate) => {
    dispatch({
      type: types.FETCHING_ANALYTICS
    })

    const analytics = await dispatch(
      fetch(
        `/analyticsApplication`,
        {
          type: "POST",
          data: {
            requestPayload: {
              startDate,
              endDate
            }
          }
        },
        {
          timeout: 300000
        }
      )
    )

    const googleAnalytics = await dispatch(
      fetch(
        `/analytics/google`,
        {
          type: "POST",
          data: {
            requestPayload: {
              startDate,
              endDate
            }
          }
        },
        {
          timeout: 300000
        }
      )
    )

    const pypAnalytics = await dispatch(
      fetch(
        `/analyticsPYP`,
        {
          type: "POST",
          data: {
            requestPayload: {
              startDate,
              endDate
            }
          }
        },
        {
          timeout: 300000
        }
      )
    )

    // console.log(pypAnalytics)

    const dlVideoAnalytics = await dispatch(
      fetch(
        `/analytics/videos`,
        {
          type: "POST",
          data: {
            requestPayload: {
              startConstraint: startDate,
              endConstraint: endDate
            }
          }
        },
        {
          timeout: 300000
        }
      )
    )

    const dlAnalytics = await dispatch(
      fetch(
        `/analyticsDigitalLibrary`,
        {
          type: "POST",
          data: {
            requestPayload: {
              startDate,
              endDate
            }
          }
        },
        {
          timeout: 300000
        }
      )
    )

    const mcAnalytics = await dispatch(
      fetch(
        "/analytics/mailchimp",
        {
          type: "POST",
          data: {
            requestPayload: {
              startDate,
              endDate
            }
          }
        },
        {
          timeout: 300000
        }
      )
    )

    const filterMCdata =
      mcAnalytics.data &&
      mcAnalytics.data.length &&
      mcAnalytics.data.filter((campaign) => campaign.recipient_count > 0)

    dispatch({
      type: types.FETCHED_ANALYTICS,
      data: {
        analyticsData: {
          ...analytics.data,
          ...googleAnalytics.data,
          ...dlAnalytics.data,
          ...pypAnalytics.data,
          mailchimp_analytics: filterMCdata
        },
        videoData: {
          categoryBreakdown: dlVideoAnalytics.data.categoryBreakdown,
          typeBreakdown: dlVideoAnalytics.data.typeBreakdown
        }
      }
    })
  }
}

// deprecated - our attempt at cache busting Chrome
export const fetchApplicationMetaData = () => {
  return async (dispatch, getState) => {
    const metadata = await dispatch(
      fetch(`/appMeta`, {
        type: "GET"
      })
    )

    const frontEndPackageJson = require("../../../../package.json")
    if (frontEndPackageJson.version < metadata.data.front_end_version) {
      window.location.reload(true)
    }
  }
}

export const acceptPrivacyPolicy = (userId) => async (dispatch) => {
  await dispatch(
    fetch(`/acceptPrivacyPolicy`, {
      type: "PUT",
      data: {
        requestPayload: {
          userId
        }
      }
    })
  )
}

export const scheduleDVSMeeting = () => async (dispatch) => {
  await dispatch(
    fetch(`/scheduleDVSMeeting`, {
      type: "PUT",
      data: {}
    })
  )
}
