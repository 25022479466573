  import React, { useEffect, useState } from "react"
  import { withRouter, Link } from "react-router-dom"
  import { useSelector, useDispatch } from "react-redux"
  import Loader from "react-loader-spinner"
  import PropTypes from "prop-types"
  import Checkbox from "@material-ui/core/Checkbox"
  import FormControlLabel from "@material-ui/core/FormControlLabel"
  import CommitButtonWithClickState from "../CommitButtonWithClickState/CommitButtonWithClickState"
  import colors from "../../Constants/colors"
  import FormHeader from "../FormHeader/FormHeader"
  import FormFooter from "../FormFooter/FormFooter"
  import AccountFormContainer from "../AccountFormContainer/AccountFormContainer"
  import { FormMiddle } from "../FormMiddle/FormMiddle"
  import InputGroup from "../InputGroup/InputGroup"
  import Actions from "../../Redux/Actions"

  import "./CreateAccount.css"

  const createAccountInputs = [
    {
      id: "firstName",
      placeHolder: "First Name",
      inputType: "text",
      value: "",
      className: "firstName",
      submittable: false,
      required: true
    },
    {
      id: "lastName",
      inputType: "text",
      placeHolder: "Last Name",
      value: "",
      className: "lastName",
      submittable: false,
      required: true
    },
    {
      id: "email",
      inputType: "text",
      placeHolder: "Email",
      value: "",
      className: "email",
      submittable: false,
      required: true
    },
    {
      id: "password",
      inputType: "text",
      placeHolder: "Password",
      type: "password",
      value: "",
      className: "password",
      submittable: false,
      required: true
    },
    {
      id: "confirmPassword",
      inputType: "text",
      type: "password",
      placeHolder: "Confirm Password",
      value: "",
      className: "confirmPassword",
      submittable: false,
      required: true
    }
    // {
    //   id: "isSpouse",
    //   title: 'Please choose the affiliation that best describes your primary reason for seeking services from the COMMIT Foundation.',
    //   inputType: "hidden",
    //   value: false,
    //   className: "isSpouse",
    //   submittable: false,
    //   required: false
    // }
  ]

  const styles = {
    loginText: {
      color: colors.fadedRed,
      fontSize: "14px",
      fontFamily: "Lato-Bold",
      marginLeft: "4px",
      textDecoration: "none"
    },
    createAccountButtonContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center"
    },
    componentContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "30px",
      color: colors.text,
      lineHeight: "30px",
      letterSpacing: 0,
      marginTop: "60px"
    },
    loginButton: {
      borderRadius: "19px",
      border: "solid 1px #e1373a",
      color: colors.fadedRed,
      backgroundColor: "#ffffff"
    },
    footerText: {
      margin: "0px",
      fontSize: "13px"
    },
    createButtonHover: {
      backgroundColor: "#ffffff",
      color: "#E1373A",
      borderColor: "#E1373A"
    },
    createButton: {
      width: "26%",
      borderRadius: "3px",
      transition: "all 0.3s ease-in-out",
      backgroundColor: colors.fadedRed,
      color: colors.white
    },
    privacyPolicyRow: {
      display: "flex",
      width: "100%"
    },
    privacyPolicyLink: {
      color: colors.fadedRed
    },
    privacyPolicyError: {
      color: colors.fadedRed,
      fontSize: "0.75rem",
      lineHeight: "1.6",
      width: "100%",
      fontFamily: "Lato-SemiBold"
    },
    userTypeError: {
      color: colors.fadedRed,
      fontSize: "0.75rem",
      lineHeight: "1.6",
      width: "100%",
      fontFamily: "Lato-SemiBold"
    }
  }

  const CreateAccount = ({ history }) => {
      const [selectedValue, setSelectedValue] = useState('');

      const handleChange = (event) => {
          setSelectedValue(event.target.value);
      };
      const dispatch = useDispatch()
      const [isPrivacyPolicyChecked, setPrivacyPolicyChecked] = useState(false)
      const [privacyPolicyError, setPrivacyPolicyError] = useState(null)
      const [userTypeError, setUserTypeError] = useState(null)

      const { exception, creatingAccount } = useSelector((state) => ({
          exception: state.errors.exception,
          creatingAccount: state.user.creatingAccount
      }))

      const createAccount = (checkSubmittability, inputs) => async () => {
        setPrivacyPolicyError(null);
        setUserTypeError(null);
    
        if (!isPrivacyPolicyChecked) {
          setPrivacyPolicyError("You must accept the Privacy Policy to proceed");
          return;
        }
    
        if (!selectedValue) {
          setUserTypeError("Please select the affiliation that best describes your primary reason.");
          return;
        }
    
        if (checkSubmittability()) {
          let isSpouse;
          if (selectedValue === "0") {
              isSpouse = 0; // Service Member/Veteran
          } else if (selectedValue === "1") {
              isSpouse = 1; // Spouse
          } else if (selectedValue === "2") {
              isSpouse = 2; // Non-DOD IC
          }
          
          inputs.push({
            id: "isSpouse",
            inputType: "hidden",
            value: isSpouse,
            className: "isSpouse",
            submittable: false,
            required: false
        });
          await dispatch(Actions.resetErrors());
          await dispatch(Actions.createAccount(inputs, history));
        }
    }

    const confirmPasswordValidator = (inputData) => (input) => {
      if (input.id === "password" || input.id === "confirmPassword") {
        const password = inputData.find(({ id }) => id === "password").value
        const confirmPassword = inputData.find(
          ({ id }) => id === "confirmPassword"
        ).value
        if (password !== confirmPassword) {
          return {
            ...input,
            error: true,
            errorMessage: "Password must match."
          }
        }
        return input
      }
      return input
    }

    const passwordLengthValidator = () => (input) => {
      if (
        (input.id === "password" || input.id === "confirmPassword") &&
        input.value
      ) {
        return {
          ...input,
          error: input.value.length < 7,
          errorMessage: "Password must be at least 8 characters long."
        }
      }

      return input
    }

    const emailValidator = () => (input) => {
      if (input.id === "email" && input.value) {
        // eslint-disable-next-line
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return {
          ...input,
          error: !re.test(input.value),
          errorMessage: "Invalid Email"
        }
      }
      return input
    }

    const requiredValidators = () => (input) => ({
      ...input,
      error: !!(input.required && input.value.length === 0),
      errorMessage: "Required"
    })

    useEffect(() => {
      document.title = "Create Account"
      document.getElementById("Content-Container").scrollTop = 0

      dispatch(Actions.logout())
    }, [])

    if (creatingAccount) {
      return (
        <Loader type="Oval" color={colors.midnight} height="100" width="100" />
      )
    }

    return (
      <div style={styles.componentContainer}>
        <InputGroup
          validators={[
            confirmPasswordValidator,
            passwordLengthValidator,
            emailValidator,
            requiredValidators
          ]}
          containerClass="createAccountContainer"
          inputData={createAccountInputs}
          renderComponent={({
            inputComponent,
            inputData,
            checkSubmittability
          }) => (
            <AccountFormContainer>
              <FormHeader
                styler={{
                  maxWidth: "200px"
                }}
                type={exception ? "exception" : "default"}
                showFAQLink={exception && exception.type === "system"}
                subTitle={
                  exception
                    ? exception.message
                    : "Enter your details below. We recommend using \n a personal (non-government) email address."
                }
                title="Create Your COMMIT Account"
              />
              <div style={{ width: "100%" }}>{inputComponent}</div>
              <div style={{ width: "100%" }}>
                  <div className='form-group'>
                      <div className='text-muted-label'>Please choose the affiliation that best describes your primary reason for seeking services from the COMMIT Foundation.</div>
                      <div className='btn-group-toggle' data-toggle="buttons">
                      <label className={`btn btn-red mr-2 ${selectedValue === '0' ? 'active' : ''}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                            <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                        </svg>
                        <input type="radio" name="isSpouse" value="0" onChange={handleChange} /> Service Member/Veteran
                    </label>

                    <label className={`btn btn-red ${selectedValue === '1' ? 'active' : ''}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                            <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                        </svg>
                        <input type="radio" name="isSpouse" value="1" onChange={handleChange} /> Spouse
                    </label>

                    <label className={`btn btn-red ml-2 ${selectedValue === '2' ? 'active' : ''}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                            <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                        </svg>
                        <input type="radio" name="isSpouse" value="2" onChange={handleChange} /> Non-DOD IC
                    </label>
                      </div>
                      <span style={styles.userTypeError}>{userTypeError}</span>
                  </div>
              </div>
              <div style={styles.privacyPolicyRow}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isPrivacyPolicyChecked}
                      onChange={(event) =>
                        setPrivacyPolicyChecked(event.target.checked)
                      }
                    />
                  }
                  label={
                    <div>
                      Accept{" "}
                      <a
                        href={process.env.REACT_APP_PRIVACY_POLICY_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={styles.privacyPolicyLink}
                      >
                        Privacy Policy
                      </a>
                    </div>
                  }
                />
              </div>
              <span style={styles.privacyPolicyError}>{privacyPolicyError}</span>
              <FormMiddle>
                <CommitButtonWithClickState
                  type="red"
                  clickHandler={createAccount(checkSubmittability, inputData)}
                  style={{ width: "154px" }}
                >
                  Create Account
                </CommitButtonWithClickState>
              </FormMiddle>
              <FormFooter>
                <p style={styles.footerText}>Already have an account?</p>
                <Link to="/account/login" style={styles.loginText}>
                  Login
                </Link>
              </FormFooter>
              <FormFooter>
                <p style={styles.footerText}>
                  Looking for Veteran Spouse Programming?
                </p>
                <a style={styles.loginText} href="https://www.commitfoundation.org/events">
                  Click Here.
                </a>
              </FormFooter>
            </AccountFormContainer>
          )}
        />
      </div>
    )
  }

  CreateAccount.propTypes = {
    history: PropTypes.shape({ push: PropTypes.func }).isRequired
  }

  export default withRouter(CreateAccount)
