import React from "react"
import InputGroup from "../InputGroup/InputGroup"
import "./ProfileMilitaryOne.css"
import { connect } from "react-redux"

const ProfileMilitaryOne = (props) => {
  let is_spouse = props.application.application.is_spouse;
  const data = [
    {
      id: 0,
      className: "branchOfService",
      inputType: "select",
      options: [
        { id: 0, value: "Air Force" },
        { id: 1, value: "Army" },
        { id: 5, value: "Coast Guard" },
        { id: 2, value: "Marines" },
        { id: 4, value: "National Guard" },
        { id: 3, value: "Navy" },
        { id: 7, value: "Space Force" },
        { id: 6, value: "Civilian" }
      ],
      placeHolder: "Branch",
      userField: "military.branch",
      title: "What is your branch of service?"
    },
    {
      id: 1,
      inputType: "text",
      className: "specialtyProfile",
      placeHolder: "Military Occupation (e.g. Helicopter Pilot)",
      userField: "military.specialty",
      title: `Military Specialty`
    },
    {
      id: 2,
      userField: "military.lastRank",
      placeHolder: "Final Rank",
      className: "rank",
      inputType: "select",
      options: [
        { id: 0, value: "E-1" },
        { id: 1, value: "E-2" },
        { id: 2, value: "E-3" },
        { id: 3, value: "E-4" },
        { id: 4, value: "E-5" },
        { id: 5, value: "E-6" },
        { id: 6, value: "E-7" },
        { id: 7, value: "E-8" },
        { id: 8, value: "E-9" },
        { id: 10, value: "O-1" },
        { id: 12, value: "O-2" },
        { id: 13, value: "O-3" },
        { id: 14, value: "O-4" },
        { id: 16, value: "O-5" },
        { id: 17, value: "O-6" },
        { id: 18, value: "O-7" },
        { id: 19, value: "O-8" },
        { id: 20, value: "O-9" },
        { id: 21, value: "O-10" },
        { id: 10, value: "W-1" },
        { id: 11, value: "W-2" },
        { id: 12, value: "W-3" },
        { id: 13, value: "W-4" },
        { id: 14, value: "W-5" },
        ...(is_spouse === 2 ? [{ id: 22, value: "Non-DOD-IC" }] : [])
      ],
      title: "What was your final rank?"
    },
    {
      id: 3,
      userField: "military.lastDutyPosition",
      inputType: "text",
      className: "duty",
      placeHolder: "Last Duty Position",
      title: "What was the last duty position you held?"
    }
  ]

  Object.byString = function (o, s) {
    s = s.replace(/\[(\w+)\]/g, ".$1") // convert indexes to properties
    s = s.replace(/^\./, "") // strip a leading dot
    const a = s.split(".")
    for (let i = 0, n = a.length; i < n; ++i) {
      const k = a[i]
      if (o && k in o) {
        o = o[k]
      } else {
        return
      }
    }
    return o
  }

  const inputDataWithValues = data.map((input) => {
    return {
      ...input,
      value:
        Object.byString(props.application.application, input.userField) || ""
    }
  })

  return (
    <InputGroup
      {...props}
      containerClass="profileMilitaryOneContainer"
      inputData={inputDataWithValues}
      renderComponent={({ inputComponent, inputData, checkSubmittability }) => {
        return (
          <>
            {props.renderComponent({
              ...props,
              inputData,
              inputComponent,
              checkSubmittability
            })}
          </>
        )
      }}
    />
  )
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    application: state.application
  }
}

export default connect(mapStateToProps)(ProfileMilitaryOne)
