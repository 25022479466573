import React from "react"
import { withRouter } from "react-router-dom"
import queryString from "query-string"
import { useSelector } from "react-redux"
import { profileSteps } from "../../Redux/Reducers/Application/profileMetadata"
import { spouseProfileSteps } from "../../Redux/Reducers/SpouseApplication/spouseProfileMetadata"
import UserFieldEditorHeader from "../UserFieldEditorHeader/UserFieldEditorHeader"

const ProfileHeader = (props) => {
  
  const reduxStateData = useSelector((state) => {
    const pageTitle = queryString.parse(props.history.location.search).title
    const stepOd = profileSteps.find((elem) => elem.stepId === pageTitle)
    const stepSpouse = spouseProfileSteps.find((elem) => elem.stepId === pageTitle)
    const { stepId } = stepOd ? stepOd : stepSpouse
    const item = (state.user.user.is_spouse === 2 || state.user.user.is_spouse === 0)
    ? state.application.profileMetadata.find((item) =>
        item.steps.some((step) => step.stepId === stepId)
      )
    : state.spouseApplication.spouseProfileMetadata.find((item) =>
        item.steps.some((step) => step.stepId === stepId)
      );

    const step = item.steps.find((step) => step.stepId === stepId)

    return {
      title: step.title,
      sectionTitle: item.title,
      stepsLength: item.steps.length,
      currentStepIndex: item.steps.findIndex((step) => step.stepId === stepId),
      description: step.body
    }
  })

  if (!reduxStateData) return null; // Handle the case where reduxStateData is not available

  return (
    <UserFieldEditorHeader
      sectionTitle={reduxStateData.sectionTitle}
      title={reduxStateData.title}
      currentStepIndex={reduxStateData.currentStepIndex}
      stepsLength={reduxStateData.stepsLength}
      description={reduxStateData.description}
    />
  )
}


export default withRouter(ProfileHeader)
